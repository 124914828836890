hr{margin: 0;}

//.slider-arrow {
//  background-repeat: no-repeat;
//  height: 40px;
//  width: 40px;
//  i {display: none;}
//  &.slider-arrow-left {
//    background-image: url(/s~artspheretemplate19/2019/back.png);
//  }
//  &.slider-arrow-right {
//    background-image: url(/s~artspheretemplate19/2019/forward.png);
//  }
//}