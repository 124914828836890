body { background-color: $COL6; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 40px;

}
h2 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 12px;

}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: $COL9}
/* Primary:12 */
.MES12 {
background-color: $COL11;
color: $COL15;
 }
/* News Grid:19 */
.MES19 {
background-color: $COL5;
&:hover, &.hover { background-color: $COL4;}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: $COL11;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Share Buttons:18 */
.me-block.me-ShareButton .MES18{
figure.MEC18 { background-color: $COL6;
&:hover{background-color: $COL6;}
 }
figure.MEC18 { border-color: $COL11;
border-style: solid;
&:hover{border-color:$COL11;}
border-width: 1px;
 }
figure.MEC18{ a { font-size: 100px;

color: $COL11;
 }
&:hover a {color: $COL11;}
 }

 }
/* Form:17 */
.me-block.me-Form.MES17,  body.MES17 {background-color:transparent;
  
 @include placeholder($COL6);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 0px 0px 2px 0px;
border-color: $COL1;
background-color: $COL5;
color: $COL6;
}
::-webkit-input-placeholder { color: $COL6; opacity: 0.5;}
:-moz-placeholder {color: $COL6; opacity: 0.5;} 
::-moz-placeholder {color: $COL6; opacity: 0.5;}
:-ms-input-placeholder {color: $COL6; opacity: 0.5;}

  }
/* Form Submit Button:16 */
.MES16 {
background-color: $COL4;
&:hover { background-color: $COL12;}
color: $COL11;
&:hover { color: $COL11;}
border-radius: 20px;
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: $COL5;
&:hover { border-color: $COL5; }
 }
/* News List:15 */
.MES15 {
background-color: $COL4;
color: $COL11;
 }
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL11;
 }
cite.MEC15{
color: $COL11;
}
/* Header Scroll Panel:14 */
.MES14 {
background-color: $COL10;
 }
/* Primary:13 */
.MES13 {
background-color: $COL11;
color: $COL15;
 }
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL15;
 }
cite.MEC13{
color: $COL15;
}
/* Main Menu white:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL3;
font-size: 16px;
@media #{$large-up} {
font-size: 24px;
}text-transform: uppercase;
}
 &:hover > a.MEC11{color: $COL1;
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC11.active { & > a{ color: $COL1;}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Main Menu:2 */
nav.me-Menu.MES2 {
& .menu-item.MEC2, & .menu-item.MEC2 > div.MEC2{ & > a.MEC2{color: $COL2;
font-size: 16px;
@media #{$large-up} {
font-size: 24px;
}text-transform: uppercase;
}
 &:hover > a.MEC2{color: $COL1;
}
 }
&.horizontal > .menu-item.MEC2 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC2 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC2 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC2 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC2.active { & > a{ color: $COL1;}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Horizontal Main Menu2:10 */
nav.me-Menu.MES10 {
& .menu-item.MEC10, & .menu-item.MEC10 > div.MEC10{ & > a.MEC10{color: $COL3;
text-transform: uppercase;
}
 &:hover > a.MEC10{color: $COL1;
}
 }
&.horizontal > .menu-item.MEC10 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC10 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC10 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC10 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 30px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* About List :9 */
.MES9 {
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
cite.MEC9{
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Banner Panel:8 */
.MES8 {
background-color: $COL8;
color: $COL3;
padding: 15px;

 }
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL3;
 }
cite.MEC8{
color: $COL3;
}
/* Body Style:7 */
.MES7 {
background-color: $COL11;
&:hover, &.hover { background-color: $COL11;}
color: $COL6;
 }
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL3;
 }
cite.MEC7{
color: $COL6;
}
/* Horizontal Main Menu:6 */
nav.me-Menu.MES6 {
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: $COL2;
text-transform: uppercase;
}
 &:hover > a.MEC6{color: $COL1;
}
 }
&.horizontal > .menu-item.MEC6 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC6 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC6 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC6 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 30px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Responsive Menu:5 */
nav.responsive-menu {
.menu-item.MEC5{background-color: $COL2;
}
& .menu-item.MEC5, & .menu-item.MEC5 > div.menu-item-wrap{ & > a.MEC5, & > i{color: $COL4;
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC5 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* Copyright Panel:4 */
.MES4 {
font-size: 12.8px;
 }
cite.MEC4{
font-size: 12.8px;
}
/* Home Carousel:3 */
.MES3 {
& .slider-arrow {color: $COL10;
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{background-color: $COL5;
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL1;
border-radius: 50%;
border-style:solid;
border-width:20px;
@media #{$medium-up} {
border-width: 20px;
};
@media #{$large-up} {
border-width: 20px;};
&:hover{border-color: $COL2;
;}
background-color: $COL3;
background-clip: padding-box;
&:hover {background-color: $COL4;}
width:5px;
height:5px;
@media #{$medium-up} {
width:5px;
height:5px;
};
@media #{$large-up} {
width:5px;
height:5px;
};
&:hover{background-color: $COL4;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL2;
background-color: $COL4;
  }
}
 }
/* Buy Button:20 */
.MES20 {
background-color: $COL13;
&:hover { background-color: $COL14;}
color: $COL6;
padding: 15px 30px;

 }
