$COL1: #aaaaaa; //Light Grey
$COLFLAT1: #aaaaaa; //Light Grey
$COL2: #444444; //Dark Grey
$COLFLAT2: #444444; //Dark Grey
$COL3: #ffffff; //White
$COLFLAT3: #ffffff; //White
$COL4: #e8e8e8; //black
$COLFLAT4: #e8e8e8; //black
$COL5: rgba(0,0,0,0); //
$COLFLAT5: #808080; //
$COL6: #ffffff; //
$COLFLAT6: #ffffff; //
$COL7: #1b1b1b; //
$COLFLAT7: #1b1b1b; //
$COL8: rgba(0,0,0,0.4); //Black 40%
$COLFLAT8: #4d4d4d; //Black 40%
$COL9: #1b1b1b; //
$COLFLAT9: #1b1b1b; //
$COL10: rgba(255,255,255,0.4); //White 40%
$COLFLAT10: #b3b3b3; //White 40%
$COL11: #1b1b1b; //
$COLFLAT11: #1b1b1b; //
$COL12: #b3bdbf; //grey news block rollover
$COLFLAT12: #b3bdbf; //grey news block rollover
$COL13: #43a047; //Green
$COLFLAT13: #43a047; //Green
$COL14: rgba(67,160,71,0.69); //Green Transparent
$COLFLAT14: #569659; //Green Transparent
$COL15: #ffffff; //
$COLFLAT15: #ffffff; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
